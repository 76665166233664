/* src/components/carta/CartaBasica.css */

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(../../fonts/Helvetica.ttf) format('truetype');
    font-weight: 400;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica-thin';
    src: local('Helvetica-thin'), url(../../fonts/Helvetica-thin.ttf) format('truetype');
    font-weight: 300;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica-bold';
    src: local('Helvetica-bold'), url(../../fonts/Helvetica-Bold.ttf) format('truetype');
    font-weight: 700;
    font-display: swap;
}


.scrollbar {
    display: none;
}

.restaurant-image {
    margin-top: 10px;
    width: 180px;
}

.carta-basica {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(92deg, hsl(214, 0%, 11%), hsl(214, 0%, 11%));
    color: #000000;
    overflow-y: auto;
    z-index: 100;
    padding: 0;
    box-sizing: border-box;
}

.carta-basica .carta-container {
    background: #fff;
    padding: 20px;
    position: relative;
}

.carta-basica .carta-container section:last-child {
    padding-bottom: 10%;
    margin-bottom: 10%;
    border-bottom: 1px solid;
    display: flex;
  flex-direction: column;
}

.close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    width: 30px;
    filter: drop-shadow(3px 3px 3px rgb(192, 192, 192));
    border-radius: 50%;
}

.carta-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.menu-section {
    margin-bottom: 20px;
}

.menu-title {
    font-family: 'Helvetica-thin';
    font-weight: 300;
    font-size: 1.9em;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid;
    border-top: 1px solid;
    padding-top: 0.8em;
    padding-bottom: 0.4em;
}

h3 {
    font-family: 'Helvetica-thin';
    font-weight: 300 !important;
    font-size: 25px;
    text-transform: uppercase;
    border-bottom: 1px solid;
    padding-bottom: 0.4rem;
    width: 85%;
    color: #000000;
    

}

ul {
    list-style: none;
    padding-left: 0;
}

.menu-dish-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    gap: 20px;
}

.contenedor-menu .menu-dish-item {
    width: 85%;
}

.menu-dish-name {
    font-family: 'Helvetica-bold';
    font-size: 19px;
    line-height: 1;
    color: #000000;
}

.menu-alergenos {
    display: block;
    margin-top: 5px;
    font-family: 'Helvetica';
    font-size: 13px;
    color: #000000;
    font-weight: 300;

}

.dish-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 20px;
}

.dish-name {
    font-family: 'Helvetica-bold';
    font-size: 19px;
    width: 80%;
}

.dish-name span:last-child {
    font-family: 'Helvetica';
}

.dish-name span:last-child:before {
    content: " ";
}

.dish-precio {
    font-family: 'Helvetica-bold';
    font-size: 19px;

}

.menu-item {
    margin-bottom: 10px;
}



@media only screen and (min-width: 1000px) {

    .carta-basica .carta-container {
        max-width: 500px;
        min-height: 100vh;
        margin: 0 auto;
        border-radius: 8px;
        /* margin-top: 2em !important;*/
        margin-bottom: 2em !important;
        box-shadow: 5px 5px 5px 0px #000000, 9px -4px 38px 34px rgba(0, 0, 0, 0);
    }
}