/* src/components/menu/VideoModal.css */


.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }


.video-modal-content {
    position: relative;
    background-color: black;
    padding: 0px;
    border-radius: 10px;
    max-width: 90vw;
    max-height: 90vh;
    max-width: 480px;
    overflow: hidden;
  }

.video-player-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0px;
}

.video-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    font-size: 28px;
    background: black;
    border-bottom-left-radius: 10px;
    padding: 20px;
}

@media (max-width: 768px) {
    .video-modal-overlay {
        padding: 15px;
    }

    .video-modal-content {
        width: 100%;
        max-height: 75vh;
        overflow: hidden;
    }

    .video-modal-close {
        top: 0px;
        right: 0;
        
    }
}

@media (max-width: 480px) {
    .video-modal-overlay {
        padding: 10px;
    }

    .video-modal-content {
        width: 100%;
        /*aspect-ratio: 9/16;*/
    }
}

@media (max-height: 500px) and (orientation: landscape) {
    .video-modal-content {
        width: auto;
        height: 80vh;
        aspect-ratio: 16/9;
    }
}